import type { AuthOptions } from 'auth0-js'
import { hasCookie } from 'cookies-next'
import type { Join, Trim } from 'type-fest'
import type { Dataset } from '~/types'
import { getCookie } from './cookie'

export const DEV = process.env.NODE_ENV === 'development'

export const styles = {
	spacing: '2.5rem',
	spacingMobile: '1rem',
	sectionWidth: {
		full: '90rem', // TODO Use Breakout instead
		standard: '90rem',
		medium: '75rem',
		small: '50rem'
	}
} as const

export const DATASET = (): Dataset => {
	const cookie = getCookie('dataset')
	if (cookie) return cookie

	if (process.env.NODE_ENV === 'development') return 'development'
	if (!process.env.NEXT_PUBLIC_VERCEL_ENV) return process.env.NODE_ENV === 'production' ? 'production' : 'development'
	if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') return 'development'
	return 'production'
}

/** Returns null if we are unabled to determine whether next is in preview mode or not  */
export const IS_NEXT_PREVIEW = (): boolean | null => {
	if (hasCookie('__next_preview_data')) return true
	return getCookie('is_preview')
}

export const STRIPE = {
	publicKey: process.env.NEXT_PUBLIC_STRIPE_PKEY,
	config: {
		apiVersion: '2022-11-15'
	}
} as const

export type Auth0ResponseType = 'code' | 'token' | 'id_token' | ' '
export interface Auth0Options extends AuthOptions {
	/**
	 * type of the response used by OAuth 2.0 flow. It can be any space separated
	 * list of the values `code`, `token`, `id_token`.
	 * {@link https://openid.net/specs/oauth-v2-multiple-response-types-1_0.html}
	 */
	responseType: Trim<Join<[Auth0ResponseType, Auth0ResponseType, Auth0ResponseType], ' '>>
	connection: string
}

export const AUTH0_CONFIG: Auth0Options = {
	domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
	clientID: process.env.NEXT_PUBLIC_AUTH0_CLIENTID,
	audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
	connection: 'Username-Password-Authentication',
	scope: 'openid email profile offline_access',
	responseType: 'token id_token'
} as const

export const BASE_URL =
	typeof location !== 'undefined'
		? location.origin
		: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'main'
		? 'https://old.nomono.co'
		: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF !== undefined
		? `https://${process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL}`
		: process.env.NEXT_PUBLIC_VERCEL_URL
		? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
		: 'http://localhost:3000'

export const CUSTOMER_API = process.env.NEXT_PUBLIC_CUSTOMER_API_URL

/** Revalidation time in seconds */
export const REVALIDATE_TIME = 5 * 60

export const DEFAULT_TAX_NAME = 'VAT'
