var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1.121.8"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { ExtraErrorData } from '@sentry/integrations'
import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const ignoreErrors = ['Failed to fetch', 'NetworkError when attempting to fetch resource.', 'Load failed']

Sentry.init({
	dsn: SENTRY_DSN || 'https://4253a0a561da46b08524526ee0a0d767@o999735.ingest.sentry.io/6474781',
	ignoreErrors,
	integrations: [...Sentry.defaultIntegrations, new Sentry.BrowserTracing(), new ExtraErrorData()],

	environment: process.env.NEXT_PUBLIC_VERCEL_ENV,

	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 0.1,

	beforeBreadcrumb(breadcrumb, _hint) {
		if (breadcrumb.category === 'console' && ['info', 'log'].includes(breadcrumb.level ?? '')) return null
		return breadcrumb
	},

	beforeSend(event, hint) {
		const stack =
			hint?.syntheticException?.stack ??
			(hint?.originalException
				? typeof hint.originalException === 'string'
					? hint.originalException
					: (hint.originalException as Error).stack ??
					  hint?.syntheticException?.message ??
					  (hint.originalException as Error).message
				: null)

		if (ignoreErrors.some((error) => stack?.includes(error))) return null
		return event
	}

	// ...
	// Note: if you want to override the automatic release value, do not set a
	// `release` value here - use the environment variable `SENTRY_RELEASE`, so
	// that it will also get attached to your source maps
})
